import API from 'services/api/index';
import { 
	SEARCH_TRANSPORT_ORDER,
    LOADING_SEARCH_ORDER,
    SELECT_NUMBER_ORDER,
    DATA_ALL_ORDERS,
    LOADING_DASHBOARD_ORDER,
    DATA_WITHDRAWALS_ALL,
    COUNTED_ORDERS_ALL,
    DATA_ORDER_INVOICE,
    DATA_RESET_ORDERS
} from 'services/types/transportOrderType';

import endPoints from 'services/routes/';



export const requestSuccess = (type, payload) => {
    return { type, payload };
};

export const getLoading = (value, messageText) => async (dispatch, getState) => {
    const payload = {
      data: null,
      loading: value,
      message: messageText,
    };
    dispatch(requestSuccess(LOADING_SEARCH_ORDER, payload));
  };

  export const setNumberOrder = (value) => async (dispatch, getState) => {
    const payload = value;
    dispatch(requestSuccess(SELECT_NUMBER_ORDER, payload));
  };

  

export const searchOrden = (order_number) => async (dispatch, getState) => {
    try {
        const { userData } = getState().users

      let response = await API.get(endPoints.transportOrder.getOne(order_number, userData?.company_id));
  
      const { status, data, message } = response.data;
  
      if (status) {
        const payload = {
            data: data ?? null,
            loading: false
        };
  
        dispatch(requestSuccess(SEARCH_TRANSPORT_ORDER, payload));
      }
      return {
        status,
        message
      };
    } catch (error) {
      return { status: false, message: error };
    }
  };


  export const getLoadingDashboard = (value) => async (dispatch, getState) => {
    const payload = {
      loading: value,
    };
    dispatch(requestSuccess(LOADING_DASHBOARD_ORDER, payload));
  };

  export const getAllOrders = (page, limit, betweenDate) => async (dispatch, getState) => {
    try{
      const { userData } = getState().users
  
      let body = {
        rut: userData?.rut, 
        code_internal: userData?.internal_number, 
        company_id: userData?.company_id ,
        customer_company_id: userData?.customer_company_id
      }
  
      if(betweenDate!==null){
        body = { ...body, ...betweenDate }
      }
    
      let response = await API.post(endPoints.transportOrder.getListAll(page, limit), body);
      
      const { status, message, data, total_paginate } = response.data
  
      const payload = {
          listGlobalOrders: betweenDate===null ? [] : data,
          listOrders: data
      }
 
      dispatch(requestSuccess(DATA_ALL_ORDERS, payload));
  
      return {status, message, total_paginate }
      
      }catch(error){
    
        return {status: false, message:error}
      }
  };

  export const getAllWithadrals = (page, limit, betweenDate=null) => async (dispatch, getState) => {
    try{
      const { userData } = getState().users
 
      let body = {
        rut: userData?.rut, 
        code_internal: userData?.internal_number, 
        company_id: userData?.company_id 
      }
  
      if(betweenDate!==null){
        body = { body, ...betweenDate }
      }
    
      let response = await API.post(endPoints.withdrawals.getListAll(page, limit), body);
      
      const { data, total_paginate, status, message } = response.data
  
      const payload = data
      dispatch(requestSuccess(DATA_WITHDRAWALS_ALL, payload));

      return {status, message, total_paginate }
      
      }catch(error){
    
        return {status: false, message:error}
      }
  };

  export const getPackageSendCount= () => async (dispatch, getState) => {
    try{
      const { userData } = getState().users
  
      const body = { 
        company_id: userData?.company_id,
        rut: userData?.rut, 
        code_internal: userData?.internal_number,
        customer_company_id: userData?.customer_company_id
       }
  
      let response = await API.post(endPoints.transportOrder.countPackage, body);
      
      const { data } = response.data
  
      let payload = data
      dispatch(requestSuccess(COUNTED_ORDERS_ALL, payload));
      
      }catch(error){
        return {status: false, message:error}
      }
  };

  export const getInvoiceByClient = (page, limit, betweenDate=null) => async (dispatch, getState) => {
    try{
      const { userData } = getState().users

  
      let body = { 
        company_id: userData?.company_id,
        rut: userData?.rut, 
        code_internal: userData?.internal_number,
        customer_company_id: userData?.customer_company_id
       }

       if(betweenDate!==null){
        body = { ...body, ...betweenDate }
      }
  
      let response = await API.post(endPoints.inovice.getInoviceClient(page, limit), body);
      
      const { data, status, message } = response.data

      const payload = {
        listGlobalInvoices: betweenDate===null ? [] : data,
        listInvoices: data
    } 

      dispatch(requestSuccess(DATA_ORDER_INVOICE, payload));

      return { status, message}
      
      }catch(error){
        return {status: false, message:error}
      }
  };

  export const getDataTicketRetiroPreOrden = (printNroRetiro) => async (dispatch, getState) => {
    try{

      const { companyDataBasic } = getState().users
  
      const body = {
        withdrawal_number: printNroRetiro,
        company_id: companyDataBasic?.id
      }
      let response = await API.post(endPoints.withdrawals.previewRetiro, body);
  
      const { status, message, data, total_paginate } = response.data;
  
      return { status: status, message: message, data, total_paginate }
  
    }catch(error){
      return {status: false, message:error, data: []}
    }
  }

  export const exportExcelPDFOrders = (typeFile, nameFile) => async (dispatch, getState) => {
    try{
      const { allTransportOrders } = getState().transportOrders
      if(allTransportOrders.length>0){
        let data = allTransportOrders.map(item => {
              return {
                order_number: item.order_number,
                date_order: item.date_order_search,
                city_origin: item.city_origin,
                city_destination: item.city_destination,
                shipment_type: item.shipment_type,
                payment_form: item.payment_form,
                state: item.state,
                total_packs: item?.total_packs
              }
            })	
       
        let info = { type: typeFile, orders: data }
            let response = await API.postDownload(endPoints.orders.printExcelPdf, info)	
  
        const type = response.headers['content-type']
            const blob = new Blob([response.data], { type: type, encoding: 'UTF-8' })
            if(blob){
              let url = window.URL.createObjectURL(blob);
              let a = document.createElement('a');
              a.href = url;
              a.download = nameFile;
              a.click();
            }	     
  
            return { status: true, message: "Descargado con éxito" }
      }else{
        return { status: false, message: "No hay registros para descargar" }
      }
      }catch(error){
          return {status: false, message:error}
      }
  }

  export const resetMyOrdersList = () => async (dispatch, getState) => {
    try{
      let payload = { listOrders: [] }
      
      dispatch(requestSuccess(DATA_RESET_ORDERS, payload));		
  
      return {status: true, message:""}
      
      }catch(error){
  
        return {status: false, message:error}
      }
  };

import Layout from "components/Layout/Layout";
import MainContainer from "components/Custom/MainContainer"
import ItemOffice from "components/Office/ItemOffice"
import FilterOffice from "components/Office/FilterOffice";
import LoadingOffice from "components/Office/LoadingOffice";
import styles from "styles/Retiro.module.css";
import stylesOffice from "styles/Office.module.css";
import { useOffice } from "hooks/Office/useOffice";
import { useModalWithDrawal } from "hooks/WithDrawals/useModalWithDrawal";
import MediaQuery from 'react-responsive'

const MisOficinas = () => {

	
	const { state, formElement, actions } = useOffice()

	const { sucursales, sucursalSelected, loadingComuna, urlMap } = state

	const { control } = formElement

	const { changeSucursal, handleViewMapa } = actions

	const { openModal, show  } = useModalWithDrawal(null)

	return (
		<Layout
			modulo="mis-retiros"
			openModal={openModal}
			show={show}
		>

			<MediaQuery maxWidth={767}>
				<section className={styles["row-top"]}>
						<MainContainer
							title="Nuestras oficinas"
							subtitle="Puede ver todo el historial de sus retiros o hacer un retiro nuevo, donde lo desee."
						/>
						<FilterOffice 
							sucursales={sucursales} 
							control={control} 
							changeSucursal={changeSucursal}
						/>

						
						
						{!loadingComuna && sucursalSelected!==null && (
							<>
							<p className={stylesOffice["title"]}>{sucursalSelected?.nombre}</p>
							{
								sucursalSelected?.comunas?.length>0 && 
								sucursalSelected?.comunas?.map((item, key) => 
									<ItemOffice key={key} info={item} handleViewMapa={handleViewMapa} />
								)
							}
							</>
						)}
						{loadingComuna && <LoadingOffice  /> }

						{!loadingComuna && (
							<div className="oficina-map mt-5">
								{
									urlMap!=="" ?
									<iframe title="Mapa" src={urlMap} width="100%" height="311" allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
									: <p className={`${stylesOffice["item-info__text"]}`}>Mapa No disponible</p>
								}								
							</div>
						)}
				</section>
			</MediaQuery>
			
			<MediaQuery minWidth={767}>
				<div className="row">
					<div className={`col-md-12 col-lg-5`}>
							<section>
								<p className={stylesOffice["dsk-title"]}>Ubica la oficina</p>
								<p className={stylesOffice["dsk-subtitle"]}>más cercana a ti</p>
							</section>
						</div>
						<div className="col-md-12 col-lg-7">
						<FilterOffice 
							sucursales={sucursales} 
							control={control} 
							changeSucursal={changeSucursal}
						/>
					</div>
					<div className="col-md-12 col-lg-5">
						{!loadingComuna && sucursalSelected!==null && (
							<div>
								<p className={stylesOffice["title"]}>{sucursalSelected?.nombre}</p>
								{
									sucursalSelected?.comunas?.length>0 && 
									sucursalSelected?.comunas?.map((item, key) => 
										<ItemOffice key={key} info={item} handleViewMapa={handleViewMapa} />
									)
								}
							</div>
						)}
						{!loadingComuna && sucursalSelected?.comunas?.length===0 && (
							<div className="pt-5">
								<p className={stylesOffice["title"]}>No hay oficinas disponibles</p>
							</div>
						)}
						{loadingComuna && <LoadingOffice  /> }
					</div>
					{
						(!loadingComuna && sucursalSelected!==null) && (
							<div className={`col-md-12 col-lg-7 ${stylesOffice["dsk-img"]}`}>
								<div className="oficina-map">
									{
										urlMap!=="" ?
										<iframe title="Mapa" src={urlMap} width="100%" height="311" allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
										: <p className={`${stylesOffice["item-info__text"]}`}>Mapa No disponible</p>
									}								
								</div>
							</div>
						)
					}
				</div>
			</MediaQuery>
			
	

		</Layout>
	);

};

export default MisOficinas;
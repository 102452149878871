import API from 'services/api/index';
import { 
	FORM_PREORDEN,
	RESET_FORM_PREORDEN,
	NEXT_FORM_PREORDEN,
	SAVE_PACKAGES,
	LIST_RETIROS,
	SAVE_FORM_PREORDEN,
	CHANGE_MENU_OPTIONS,
	CHANGE_MENU_OPTIONS_RETIRO,

	LIST_INITIAL_DASHBOARD,
	LOADING_INITIAL_RETIRO,
	LIST_INITIAL_RETIRO,
	DATA_INVOICE,
	SELECTED_MULTIPLE_DESTINATATION,
	FORM_PREORDEN_DESTINATION,
	FORM_PREORDEN_PACKAGE,
	RESET_DESTINATION,
	DATA_PACKAGE_ALL,
	DATA_SEARCH_PACKAGE_ALL,
	GET_DATA_PACKAGE,

	DATA_PACKAGE_ALL_RETIRO,
	UPDATE_CONFIG_FORM_RETIRO,

	OPEN_DIALOG,
	DATA_ORDER_ALL,
	COUNTED_ORDER_ALL
} from 'services/types/withdrawalType';
import endPoints from 'services/routes/';
import { formatDataRetiro, formatListViewRetiro, formatListViewOrden, formatListViewOrdenPage } from 'utils/ToolPreOrden/DataInsertAndUpdate'
import { dataInitialState } from "data/dataInitialState"
import { formatDashboard, formatDateList } from 'utils/Global/ToolGlobal'
import { formatMonto } from "utils/formatNumber"

export const requestSuccess = (type, payload) => {
    return { type, payload };
};

export const openDialogCreate = (value) => (dispatch, getState) => {
	
	dispatch(requestSuccess(OPEN_DIALOG, value));
}

export const loadingDashboard = (value) => (dispatch, getState) => {
	const { optionsDashboardMenu } = getState().preorden

	const payload = {
		menuOptionsList: optionsDashboardMenu.map(item => {
			return {...item, isLoading: value }
		}),
		//listOptionSelected: optionsDashboardMenu[0],
		isLoadingDashboard: value
	}

	dispatch(requestSuccess(LIST_INITIAL_DASHBOARD, payload));
}


export const getMyPackagesList = (page, limit, info) => async (dispatch, getState) => {
	try{
		const { userData } = getState().users

		let body = {
			rut: userData?.rut, 
			code_internal: userData?.internal_number, 
			company_id: userData?.company_id 
		}

		if(info!==null){
			body = { body, ...info }
		}
	
		let response = await API.post(endPoints.retiros.getListAll(page, limit), body);
		
		const { data, total_paginate } = response.data

		/**let list = data.map(item => {
			const direction = `${item?.sucursal_name} - ${item?.comuna_name} - ${item?.direccion_retiro}`;
			return {
				...item,
				titleMain: `#${item?.numero_retiro}`, 
				titleSecondary: formatDateList(item?.fecha), 
				valueSecondary: `${direction.slice(0,50)} ${direction?.length>50 ? "..." : ""}`, 
				valueMain: item?.label_web, 
				type_option: "label", 
				classBorder: "", 
				classSend: "option-details__dash"
			}
		})**/

		/**let payload = formatDashboard(
			optionsDashboardMenu, 
			list, 
			(info!==null) ? false : true,
			"Mis retiros" 
		) */
		const payload = {
			packages: data
		}
		dispatch(requestSuccess(DATA_PACKAGE_ALL, payload));
		/**if(info===null){
			dispatch(requestSuccess(DATA_PACKAGE_ALL, payload));
		}else{
			dispatch(requestSuccess(DATA_SEARCH_PACKAGE_ALL, payload));
		}	**/	

		return {status: true, message:"", total_paginate }
		
    }catch(error){
	
      return {status: false, message:error}
    }
};

export const getMyOrdersList = (page, limit, info) => async (dispatch, getState) => {
	try{
		const { userData } = getState().users

		let body =  {
			company_id: userData?.company_id,
			rut: userData?.rut, 
			code_internal: userData?.internal_number
		}

	
		let response = await API.post(endPoints.orders.getListAll(page, limit), body);
		
		const { data, total_paginate } = response.data

		let payload = {
			orders: data,
		}

		dispatch(requestSuccess(DATA_ORDER_ALL, payload));	

		return {status: true, message:"", total_paginate}
    }catch(error){
      return {status: false, message:error}
    }
};

export const getPackageSendCount= () => async (dispatch, getState) => {
	try{
		const { userData } = getState().users

		const body = { 
			company_id: userData?.company_id,
			rut: userData?.rut, 
			code_internal: userData?.internal_number
		 }

		let response = await API.post(endPoints.retiros.countPackage, body);
		
		const { data } = response.data

		let payload = {
			data
		}
		dispatch(requestSuccess(COUNTED_ORDER_ALL, payload));
		
    }catch(error){
      return {status: false, message:error}
    }
};

export const getInvoiceByClient = (page, limit) => async (dispatch, getState) => {
	try{
		const { userData } = getState().users
		const { optionsDashboardMenu } = getState().preorden

		const body = { 
			company_id: userData?.company_id,
			customer_company_id: userData?.customer_company_id, 
			code_internal: userData?.internal_number
		 }

		let response = await API.post(endPoints.inovice.getInoviceClient(page, limit), body);
		
		const { listado } = response.data

		let list = listado.map(invoice => {
					return {
						number_invoice: invoice.nro_invoice,
						date_created: invoice.date_created,
						cliente: invoice.client,
						total: formatMonto(invoice.monto),
						status: "Pagado",
						classStatus: {option1: "item-status", option2: "item-status__completed"},
						url: invoice.url,
						documento: invoice.documentacion,
						count: invoice.count
					}
				})

		let payload = formatDashboard(
			optionsDashboardMenu, 
			list.slice(0,5), 
			true,
			"Mis facturas" 
		)
		
		dispatch(requestSuccess(DATA_INVOICE, payload));
		
    }catch(error){
      return {status: false, message:error}
    }
};

export const loadingSearchPackages = () => (dispatch, getState) => {
	let payload =  { options: [], isLoading: true }
	dispatch(requestSuccess(DATA_SEARCH_PACKAGE_ALL, payload));
};



export const getInvoiceByClientRetiro = (page, limit) => async (dispatch, getState) => {
	try{
		const { userData } = getState().users
		const { menuOptionsListRetiro } = getState().preorden
	
		const body = { rut: userData?.rut }

		let service = userData?.internal_number===null ? endPoints.inovice.getInoviceClient(page, limit) : endPoints.inovice.getInoviceCompany(page, limit)

		let response = await API.post(service, body);
		
		const { listado } = response.data

		let list = listado.map(invoice => {
					return {
						number_invoice: invoice.nro_invoice,
						date_created: invoice.date_created,
						cliente: invoice.client,
						total: formatMonto(invoice.monto),
						status: "Pagado",
						classStatus: {option1: "item-status", option2: "item-status__completed"},
						url: invoice.url,
						documento: invoice.documentacion,
						count: invoice.count
					}
				})

		let payload = formatDashboard(
			menuOptionsListRetiro, 
			list.slice(0,5), 
			true,
			"Mis facturas" 
		)
		
		dispatch(requestSuccess(DATA_PACKAGE_ALL_RETIRO, payload));
		
    }catch(error){
      return {status: false, message:error}
    }
};





export const getMyPackagesListRetiro = (page, limit, info) => async (dispatch, getState) => {
	try{
		const { userData } = getState().users
		const { menuOptionsListRetiro } = getState().preorden

		let body = {}

		if(info!==null){
			body = { rut: userData?.rut, code_internal: userData?.internal_number, ...info }
		}else{
			body = { rut: userData?.rut, code_internal: userData?.internal_number  }
		}

		let response = await API.post(endPoints.retiros.getListAll(page, limit), body);
		
		const { data } = response.data

		let list = data.map(item => {
			const direction = `${item?.sucursal_name} - ${item?.comuna_name} - ${item?.direccion_retiro}`;
			return {
				...item,
				titleMain: `#${item?.numero_retiro}`, 
				titleSecondary: formatDateList(item?.fecha), 
				valueSecondary: `${direction.slice(0,50)} ${direction?.length>50 ? "..." : ""}`, 
				valueMain: "Ver Retiro", 
				type_option: "link", 
				classBorder: "", 
				classSend: "option-details__dash",
				url: `/retiro/${item?.numero_retiro}/informe` 
			}
		})

		let payload = formatDashboard(
			menuOptionsListRetiro, 
			list, 
			(info!==null) ? false : true,
			"Orden de retiro" 
		)

		if(info===null){
			dispatch(requestSuccess(DATA_PACKAGE_ALL_RETIRO, payload));
		}else{
			//dispatch(requestSuccess(DATA_SEARCH_PACKAGE_ALL, payload));
		}		

		return {status: true, message:""}
		
    }catch(error){
	
      return {status: false, message:error}
    }
};

export const resetMyPackagesList = () => async (dispatch, getState) => {
	try{
		let payload = { options: [], isLoading: false }
		
		dispatch(requestSuccess(DATA_SEARCH_PACKAGE_ALL, payload));		

		return {status: true, message:""}
		
    }catch(error){

      return {status: false, message:error}
    }
};



export const getMyOrdersListRetiro = (page, limit, info) => async (dispatch, getState) => {
	try{
		const { userData } = getState().users
		const { menuOptionsListRetiro } = getState().preorden

		let body = (info!==null) ? { rut: userData?.rut, code_internal: userData?.internal_number , ...info } : { rut: userData?.rut, code_internal: userData?.internal_number  }
	
		let response = await API.post(endPoints.orders.getListAll(page, limit), body);
		
		const { data } = response.data

		let list = data.map(item => {
			return {
				...item,
				titleMain: item?.razon_social_destinatario.slice(0,20),
				titleSecondary: formatDateList(item?.fecha_preorden), 
				valueSecondary: `#${item?.numero_preorden}`,
				valueMain: item?.label_web, 
				type_option: "label", 
				classBorder: "", 
				classSend: "option-details__dash"
			}
		})

		let payload = formatDashboard(
			menuOptionsListRetiro, 
			list, 
			(info!==null) ? false : true,
			"Orden de flete" 
		)
		
		if(info===null){
			dispatch(requestSuccess(DATA_PACKAGE_ALL_RETIRO, payload));
		}else{
			//dispatch(requestSuccess(DATA_SEARCH_PACKAGE_ALL, payload));
		}	

		return {status: true, message:""}
    }catch(error){
	
      return {status: false, message:error}
    }
};

export const getMyNotification = (page, limit, info) => async (dispatch, getState) => {
	try{
		const { userData } = getState().users
		const { optionsDashboardMenu } = getState().preorden

		let body = (info!==null) ? { rut: userData?.rut, ...info } : { rut: userData?.rut }
	
		let response = await API.post(endPoints.orders.notification(page, limit), body);
		
		const { data } = response.data

		let list = data.map(item => {
			return {
				...item,
				titleMain: "No. Tracking",
				titleSecondary: formatDateList(item?.fecha_preorden), 
				valueSecondary: `#${item?.numero_preorden}`,
				valueMain: item?.label_web, 
				type_option: "label", 
				classBorder: "", 
				classSend: "option-details__dash"
			}
		})

		let payload = formatDashboard(
			optionsDashboardMenu, 
			list, 
			true,
			"Notificaciones"  
		)

		dispatch(requestSuccess(DATA_PACKAGE_ALL, payload));
		
    }catch(error){
      return {status: false, message:error}
    }
};

export const exportExcelPDFOrders = (typeFile, nameFile) => async (dispatch, getState) => {
	try{
		const { listSearchPackages } = getState().preorden
		if(listSearchPackages.length>0){
			let data = listSearchPackages.map(item => {
						return {
							numero_orden: item.numero_preorden,
							fecha: item.fecha_preorden,
							remitente: item.razon_social_remitente,
							destinatario: item.razon_social_destinatario,
							direccion_destino: item.direccion_destinatario,
							bultos: item.total_bultos,
							estado: item.label_web
						}
					})	
			let info = { type: typeFile, orders: data }
	      	let response = await API.postDownload(endPoints.orders.printExcelPdf, info)	

			const type = response.headers['content-type']
	      	const blob = new Blob([response.data], { type: type, encoding: 'UTF-8' })
	      	if(blob){
	      		let url = window.URL.createObjectURL(blob);
		        let a = document.createElement('a');
		        a.href = url;
		        a.download = nameFile;
		        a.click();
	      	}	     

	      	return { status: true, message: "Descargado con éxito" }
	  }else{
	  	return { status: false, message: "No hay registros para descargar" }
	  }
    }catch(error){
      	return {status: false, message:error}
    }
}

export const exportExcelPDFPackages = (typeFile, nameFile) => async (dispatch, getState) => {
	try{
		const { listSearchPackages } = getState().preorden
		if(listSearchPackages.length>0){
			let data = listSearchPackages.map(item => {
						return {
							numero_retiro: item.numero_retiro,
							razon_social: item.razon_social_remitente,
							fecha: item.fecha,
							direccion_retiro: item.direccion_retiro,
							label_web: item.label_web,
							sucursal_name: item?.sucursal_name,
							comuna_name: item?.comuna_name
						}
					})	
			let info = { type: typeFile, packages: data }
	      	let response = await API.postDownload(endPoints.retiros.printExcelPdf, info)	

			const type = response.headers['content-type']
	      	const blob = new Blob([response.data], { type: type, encoding: 'UTF-8' })
	      	if(blob){
	      		let url = window.URL.createObjectURL(blob);
		        let a = document.createElement('a');
		        a.href = url;
		        a.download = nameFile;
		        a.click();
	      	}	     

	      	return { status: true, message: "Descargado con éxito" }
	  }else{
	  	return { status: false, message: "No hay registros para descargar" }
	  }
    }catch(error){
      	return {status: false, message:error}
    }
}

export const loadingRetiroPanel = (value) => (dispatch, getState) => {

	const { menuOptionsListRetiro } = getState().preorden

	const payload = {
		menuOptionsList: menuOptionsListRetiro.map(item => {
			return {...item, isLoading: value }
		}),
		listOptionSelected: menuOptionsListRetiro[0],
		isLoadingRetiro: value
	}

	dispatch(requestSuccess(LOADING_INITIAL_RETIRO, payload));
}

export const listInitialRetiro = () => (dispatch, getState) => {
	
	try{
		dispatch(requestSuccess(LOADING_INITIAL_RETIRO, true));

		const { listOptionsRetiro } = dataInitialState()
		const { listMyInvoice } = getState().preorden
		
		const payload = {
			optionsRetiro: listOptionsRetiro.map(item => {
				let listPackage = item.list
				if(item.name==="Orden de retiro" || item.name==="Orden de flete"){
					item.listDsk = listPackage.slice(0,5)
				}else if(item.name==="Mis facturas"){
					item.listDsk = listMyInvoice.slice(0,5)
					item.list = listMyInvoice
				}else {
					item.listDsk = listPackage
				}

				return {...item }
			}),
			listOptionSelected: listOptionsRetiro[0],
			isLoadingRetiro: false
		}
	
		dispatch(requestSuccess(LIST_INITIAL_RETIRO, payload));
		
    }catch(error){
      return {status: false, message:error}
    }
}

export const saveFormReceive = (body, typeForm) => (dispatch, getState) => {
	try{

		const { formPreOrden } = getState().preorden

		let formInitial = formPreOrden

		let formSaveData = {}

		if(typeForm==="destination"){
			formSaveData = { 
				...formInitial, 
				destinations: [...formInitial?.destinations, ...body]
			}
		} else {
			formSaveData = { ...formInitial, ...body }
		}

		const payload = { formOrden: formSaveData }
		dispatch(requestSuccess(FORM_PREORDEN, payload));
		
    }catch(error){
      return {status: false, message:error}
    }
};

export const saveFormDestionation = (body, configForm) => (dispatch, getState) => {
	try{

		//const { infoDestination, configFormData } = getState().preorden

		//let formDestination = infoDestination
	
		let formSaveData = [ ...body ]
		let configFormUpdate = [ ...configForm ]

		const payload = { destination: formSaveData, configForm: configFormUpdate }

		dispatch(requestSuccess(FORM_PREORDEN_DESTINATION, payload));
		
    }catch(error){

      return {status: false, message:error}
    }
};

export const saveMultipleDestination = (valueMultiple) => (dispatch, getState) => {
	try{

		const payload = { multiple: valueMultiple }
	
		dispatch(requestSuccess(SELECTED_MULTIPLE_DESTINATATION, payload));
		
    }catch(error){
      return {status: false, message:error}
    }
};

export const resetFormWithDrawal = () => (dispatch, getState) => {
	try{
		let userData = JSON.parse(atob(localStorage.getItem("user")))

		let formOrden = {
			razon_social_remitente: userData.full_name,
			rut_remitente: userData?.rut,
			telefono_remitente: userData?.phone_number,
			sucursal_origen_id: userData?.sucursal_id,
			comuna_origen_id: userData?.comuna_id,
			direccion_retiro: userData?.address,
			email_remitente: userData?.email,
			observacion: "",
			multiple_destinatation: false,
			destinations: [],
			comuna_name: "",
			sucursal_name: ""
		}
		let formViewActive =  {
			title: "Quien envía",
			form: "receive"
		}

		const payload = { formOrden, formViewActive }

		dispatch(requestSuccess(RESET_FORM_PREORDEN, payload));	
	}catch(error){
		return {status: false, message:error}
	}
	
};

export const saveFromPackage = () =>  (dispatch, getState) => {
	try{

		const { infoDestination, formPreOrden } = getState().preorden

		let formInitial = formPreOrden

		let formSavePackage = { 
			...formInitial, 
			destinations: infoDestination
		}

		const payload = { formOrden: formSavePackage	}

		dispatch(requestSuccess(FORM_PREORDEN_PACKAGE, payload));

		return {status: true, message:""}
		
    }catch(error){
      return {status: false, message:error}
    }
};

export const resetDestination = () => (dispatch, getState) => {
	let destination = []
	const payload = { destination }
	dispatch(requestSuccess(RESET_DESTINATION, payload));
}

export const nextFormPreOrden = (formNext) =>  (dispatch, getState) => {
	try{

		const { formPreOrden } = getState().preorden
		let nameFormNext = { title: "", form: "" }
	
		if(formNext==="downloadTemplate"){
			nameFormNext = {
				title: `Subir plantilla datos del destinatarios y paquetes`,
				form: "downloadTemplate"
			};
		}else if(formNext==="package"){
			nameFormNext = {
				title: "",
				form: "package"
			};
		}else if(formNext==="receive"){
			nameFormNext = {
				title: "Quien envía",
				form: "receive"
			};
		}else if(formNext==="destinationMultiple"){
			let titleInitial = "Nuevo retiro a domicilio"
			if(formPreOrden.multiple_destinatation){
				titleInitial = `Previsualización de los datos`
			}
			nameFormNext = {
				title: titleInitial,
				form: "destinationMultiple"
			};
		}else if(formNext ==="destination"){
			nameFormNext = {
				title: "Quien recibe",
				form: "destination"
			};
		}else if(formNext ==="aditional"){
			nameFormNext = {
				title: "Información adicional del Retiro",
				form: "aditional"
			};
		}else if(formNext ==="completed"){
			nameFormNext = {
				title: "",
				form: "completed"
			};
		}else if(formNext==="preview"){
			nameFormNext = {
				title: "",
				form: "preview"
			};
		}else if(formNext==="confirmationMultipleDestination"){
			nameFormNext = {
				title: "Confirmación de la carga",
				form: "confirmationMultipleDestination"
			};
		}

		const payload = { formViewActive: nameFormNext	}
		
		dispatch(requestSuccess(NEXT_FORM_PREORDEN, payload));
		
		
    }catch(error){
      return {status: false, message:error}
    }
};

export const deleteRowTablePackage = (index) =>  (dispatch, getState) => {
	try{

		const { infoDestination } = getState().preorden

		let formInitial = infoDestination[0]

		let payload = {
			destionation: [{
				...formInitial,
				listPackages: formInitial.listPackages.filter((item, key) => key !== index)
			}]
		}

		dispatch(requestSuccess(SAVE_PACKAGES, payload));
		
    }catch(error){
      return {status: false, message:error}
    }
};

export const editRowTablePackage = (index) =>  (dispatch, getState) => {
	try{

		const { infoDestination } = getState().preorden

		let formInitial = infoDestination[0]
	
		const listPackages = formInitial.listPackages.map((item, i) => {
			item.visible = false

			if(i === index){
				return { ...item, visible: true }
			}
			return item
		})
	
		let payload = {
			destionation: [{
				...formInitial,
				listPackages
			}]
		}
	
		dispatch(requestSuccess(SAVE_PACKAGES, payload));
		
    }catch(error){
      return {status: false, message:error}
    }
};

export const saveRowTablePackage = (carga, index) =>  (dispatch, getState) => {
	try{

		const { infoDestination } = getState().preorden

		let formInitial = infoDestination[0]

		const listPackages = formInitial.listPackages.map((item, i) => {		
			if(i === index){
				return { ...carga, visible: false }
			}
			return item
		})

		let payload = {
			destionation: [{
				...formInitial,
				listPackages
			}]
		}

		dispatch(requestSuccess(SAVE_PACKAGES, payload));
		
    }catch(error){
      return {status: false, message:error}
    }
};

export const addNewPackages = (cargas) =>  (dispatch, getState) => {
	try{

		const { infoDestination } = getState().preorden

		let destination = infoDestination[0]
		
		let form = {
			...destination,
			listPackages: cargas.map(item => {
				return {
					...item,
					visible: false
				}
			})
		}
		const payload = { 
			destination: [form],  
			configForm: []
		}

		dispatch(requestSuccess(FORM_PREORDEN_DESTINATION, payload));
		
		return { status: true, message: "", listPackages: form.listPackages }
    }catch(error){
      return { status: false, message:error }
    }
};

export const createRetiro = (body) => async (dispatch, getState) => {
	try{

		let response = await API.post(endPoints.withdrawals.createRetiros, body);

		const { status, message, data } = response.data

		return { status, message, data }
		
    }catch(error){
      return {status: false, message:error}
    }
};

export const assignOrdenByPackage = (body, id) => async (dispatch, getState) => {
	try{

		let response = await API.post(endPoints.withdrawals.assignOrden(id), body);

		const { status, message, data } = response.data

		return { status, message, data }
		
    }catch(error){
      return {status: false, message:error}
    }
};

export const updateAssignFormRetiro = (retiro) => async (dispatch, getState) => {
	try{

		const { formPreOrden, optionsDashboardMenu, menuOptionsListRetiro } = getState().preorden

		let formInitial = formPreOrden

		let menusItems = optionsDashboardMenu

		let searchItemMenu = menusItems.findIndex(item => item.name==="Mis retiros")
		if(searchItemMenu!==-1){
			let listDskUpdate = formatListViewRetiro(menusItems[searchItemMenu]?.listDsk, formInitial, retiro)
			
			menusItems[searchItemMenu].listDsk = listDskUpdate?.slice(0,5)
			menusItems[searchItemMenu].list = listDskUpdate?.slice(0,5)
		}

		let searchItemMenuOrden = menusItems.findIndex(item => item.name==="Mis órdenes")

		if(searchItemMenuOrden!==-1){
			let listDskUpdateOrden = formatListViewOrden(formInitial, menusItems[searchItemMenuOrden].listDsk, retiro)
			menusItems[searchItemMenuOrden].listDsk = listDskUpdateOrden?.slice(0,5)
			menusItems[searchItemMenuOrden].list = listDskUpdateOrden?.slice(0,5)
		}

		searchItemMenuOrden = menusItems.findIndex(item => item.name==="Estados de envíos")

		if(searchItemMenuOrden!==-1){
			let count = menusItems[searchItemMenuOrden].listDsk[0]?.count
			menusItems[searchItemMenuOrden].listDsk[0].count = count + 1
			menusItems[searchItemMenuOrden].list[0].count = count + 1
		}

		let menuRetiro = menuOptionsListRetiro
		searchItemMenu = menuRetiro.findIndex(item => item.name==="Orden de retiro")
		if(searchItemMenu!==-1){
			let listDskUpdate = formatListViewRetiro(menuRetiro[searchItemMenu]?.listDsk, formInitial, retiro)
			menuRetiro[searchItemMenu].listDsk = listDskUpdate?.slice(0,5)
			menuRetiro[searchItemMenu].list = listDskUpdate?.slice(0,5)
		}

		searchItemMenuOrden = menuRetiro.findIndex(item => item.name==="Orden de flete")

		if(searchItemMenuOrden!==-1){
			let listDskUpdateOrden = formatListViewOrdenPage(formInitial, menuRetiro[searchItemMenuOrden].listDsk, retiro)
			menuRetiro[searchItemMenuOrden].listDsk = listDskUpdateOrden?.slice(0,5)
			menuRetiro[searchItemMenuOrden].list = listDskUpdateOrden?.slice(0,5)
		}

		const payload = { 
			formOrden: formInitial, 
			printNroRetiro: retiro?.numero,
			optionsDashboardMenu: menusItems,
			menuOptionsListRetiro: menuRetiro,
			configFormData: []
		}

		dispatch(requestSuccess(SAVE_FORM_PREORDEN, payload));
		
    }catch(error){
      return {status: false, message:error}
    }
};

export const saveFormRetiro = () => async (dispatch, getState) => {
	try{

		const { formPreOrden, optionsDashboardMenu, menuOptionsListRetiro } = getState().preorden
		const { userData, companyDataBasic } = getState().users

		let formInitial = formPreOrden

		const remitente = {
			rut_remitente: formInitial.rut_remitente,
			razon_social_remitente: formInitial.razon_social_remitente,
			direccion_retiro: formInitial.direccion_retiro,
			telefono_remitente: formInitial.telefono_remitente,
			email_remitente: formInitial.email_remitente,
			comuna_origen_id: formInitial.comuna_origen_id,
			sucursal_origen_id: formInitial.sucursal_origen_id,
			observacion: formInitial?.observacion,
			codigo_interno: userData?.internal_number
		}

		const listDestino = formInitial?.destinations
	
		const { dataDestino, cliente, observacion } = formatDataRetiro(remitente, listDestino)

		const infoRetiro = {
			direccion: formInitial?.direccion_retiro,
			oficina_id: formInitial?.comuna_origen_id,
			destinatarios: dataDestino,
			remitente: cliente,
			observacion,
			company_id: companyDataBasic?.id
		}
	
		let response = await API.post(endPoints.retiros.addRetiros, infoRetiro);

		const { status, message, data } = response.data

		let menusItems = optionsDashboardMenu

		if(status){	
	
			let searchItemMenu = menusItems.findIndex(item => item.name==="Mis retiros")
			if(searchItemMenu!==-1){
				let listDskUpdate = formatListViewRetiro(menusItems[searchItemMenu]?.listDsk, formInitial, data)
				
				menusItems[searchItemMenu].listDsk = listDskUpdate?.slice(0,5)
				menusItems[searchItemMenu].list = listDskUpdate?.slice(0,5)
			}

			let searchItemMenuOrden = menusItems.findIndex(item => item.name==="Mis órdenes")

			if(searchItemMenuOrden!==-1){
				let listDskUpdateOrden = formatListViewOrden(formInitial, menusItems[searchItemMenuOrden].listDsk, data)
				menusItems[searchItemMenuOrden].listDsk = listDskUpdateOrden?.slice(0,5)
				menusItems[searchItemMenuOrden].list = listDskUpdateOrden?.slice(0,5)
			}

			searchItemMenuOrden = menusItems.findIndex(item => item.name==="Estados de envíos")

			if(searchItemMenuOrden!==-1){
				let count = menusItems[searchItemMenuOrden].listDsk[0]?.count
				menusItems[searchItemMenuOrden].listDsk[0].count = count + 1
				menusItems[searchItemMenuOrden].list[0].count = count + 1
			}

			let menuRetiro = menuOptionsListRetiro
			searchItemMenu = menuRetiro.findIndex(item => item.name==="Orden de retiro")
			if(searchItemMenu!==-1){
				let listDskUpdate = formatListViewRetiro(menuRetiro[searchItemMenu]?.listDsk, formInitial, data)
				menuRetiro[searchItemMenu].listDsk = listDskUpdate?.slice(0,5)
				menuRetiro[searchItemMenu].list = listDskUpdate?.slice(0,5)
			}

			searchItemMenuOrden = menuRetiro.findIndex(item => item.name==="Orden de flete")

			if(searchItemMenuOrden!==-1){
				let listDskUpdateOrden = formatListViewOrdenPage(formInitial, menuRetiro[searchItemMenuOrden].listDsk, data)
				menuRetiro[searchItemMenuOrden].listDsk = listDskUpdateOrden?.slice(0,5)
				menuRetiro[searchItemMenuOrden].list = listDskUpdateOrden?.slice(0,5)
			}

			const payload = { 
				formOrden: formInitial, 
				printNroRetiro: data?.numero,
				optionsDashboardMenu: menusItems,
				menuOptionsListRetiro: menuRetiro,
				configFormData: []
			}

			dispatch(requestSuccess(SAVE_FORM_PREORDEN, payload));
		}

		return { status, message }
		
    }catch(error){
      return {status: false, message:error}
    }
};

export const getListRetiros = () => async (dispatch, getState) => {
	try{

		let response = await API.get(endPoints.retiros.getListAll);
		
		const { data, message, status } = response.data

		const payload = {
			list: data.map(item => {
				return {
					...item,
					title_btn: "Ver orden"
				}
			})
		}
		
		dispatch(requestSuccess(LIST_RETIROS, payload));

		return { status, message }
		
    }catch(error){
      return {status: false, message:error}
    }
};



/////////////////Cambio de optiones de menu
export const changeItemMenuOptions = (item) =>  (dispatch, getState) => {
	try{

		const { optionsDashboardMenu } = getState().preorden

		let listItem = []

		let menuOptions = optionsDashboardMenu.map(menu => {
			menu.selected=false
			if(item.name===menu.name){
				menu.selected=true
				listItem=menu
			}

			return menu
		})

		const payload = {
			menuOptionsList: menuOptions,
			selected: listItem
		}
		
		dispatch(requestSuccess(CHANGE_MENU_OPTIONS, payload));
		
    }catch(error){
      return {status: false, message:error}
    }
};


/////////////////Cambio de optiones de menu Retiro
export const changeItemMenuOptionsRetiro = (item) =>  (dispatch, getState) => {
	try{

		const { menuOptionsListRetiro } = getState().preorden

		let listItem = []

		let menuOptions = menuOptionsListRetiro.map(menu => {
			menu.selected=false
			if(item.name===menu.name){
				menu.selected=true
				listItem=menu
			}

			return menu
		})
	

		const payload = {
			menuOptionsList: menuOptions,
			selected: listItem
		}

		dispatch(requestSuccess(CHANGE_MENU_OPTIONS_RETIRO, payload));
		
    }catch(error){
      return {status: false, message:error}
    }
};

//////Obtener la información del paquete
export const getInfoPackage = (numero_retiro) => async (dispatch, getState) => {
	try{

		let baseInfoPackage =  {
			id: "",
			numero_retiro: "",
			razon_social_remitente: "",
			fecha: "",
			cant_preorden: "",
			comuna_name: "",
			sucursal_name: "",
			preorden: [],
			search: false
		}

		let payload = {
			infoPackage: baseInfoPackage,
			loadingInfoPackage: true
		}
		dispatch(requestSuccess(GET_DATA_PACKAGE, payload));

		let response = await API.get(endPoints.retiros.getInfoPackage(numero_retiro));

		const { status, message, data } = response.data
		
		if(status){
			let dataRetiroPreorden = data?.preorden?.map(item => {
							return {
								...item,
								destinatario: item?.razon_social_destinatario?.slice(0,18)+'...'
							}
						}) 

			payload = {
				infoPackage: { 
				...data, 
				search: true,
				preorden: dataRetiroPreorden
				},
				loadingInfoPackage: false
			}
		}else{
			payload = {
				infoPackage: {...baseInfoPackage,search: true },
				loadingInfoPackage: false
			}
		}
		

		dispatch(requestSuccess(GET_DATA_PACKAGE, payload));

		return { status, message }
		
    }catch(error){
      return {status: false, message:error}
    }
};

export const saveInfoPackage = (infoRetiro) =>  (dispatch, getState) => {
	try{

		let dataRetiroPreorden = infoRetiro?.preorden?.map(item => {
						return {
							...item,
							destinatario: item?.razon_social_destinatario?.slice(0,18)+'...'
						}
					}) 

		const payload = {
			infoPackage: { 
			...infoRetiro, 
			search: true,
				preorden: dataRetiroPreorden
			},
			loadingInfoPackage: false
		}

		dispatch(requestSuccess(GET_DATA_PACKAGE, payload));

		
    }catch(error){
      return {status: false, message:error}
    }
};

export const getInfoPackageInitial = () =>  (dispatch, getState) => {
	try{

		let baseInfoPackage =  {
			id: "",
			numero_retiro: "",
			razon_social_remitente: "",
			fecha: "",
			cant_preorden: "",
			comuna_name: "",
			sucursal_name: "",
			preorden: [],
			search: false,
			estados: []
		}

		let payload = {
			infoPackage: baseInfoPackage,
			loadingInfoPackage: false
		}
		dispatch(requestSuccess(GET_DATA_PACKAGE, payload));
			
    }catch(error){
      return {status: false, message:error}
    }
};

export const downloadInformePDF = (numeroRetiro, nameFile) => async (dispatch, getState) => {
	try{
		let response = await API.postDownload(endPoints.retiros.downloadInfo, numeroRetiro)	

		const type = response.headers['content-type']
		const blob = new Blob([response.data], { type: type, encoding: 'UTF-8' })
		if(blob){
			let url = window.URL.createObjectURL(blob);
			let a = document.createElement('a');
			a.href = url;
			a.download = nameFile;
			a.click();
		}	     

		return { status: true, message: "Descargado con éxito" }
    }catch(error){
      	return {status: false, message:error}
    }
}



export const getPreviewTicketRetiroPreOrden = (printNroRetiro, page) => async (dispatch, getState) => {
	try{
		let response = await API.get(endPoints.retiros.previewTicketRetiro(printNroRetiro, page));

		const { status, message, data, total_paginate } = response.data;

		return { status: status, message: message, data, total_paginate }

	}catch(error){
		return {status: false, message:error, data: []}
	}
}


export const updateConfigForm = (payload) => async (dispatch, getState) => {
	
	dispatch(requestSuccess(UPDATE_CONFIG_FORM_RETIRO, payload));
};
export const formatDataRetiro = (remitente, listDestino) => {

	let dataDestino = []
	
	for(let index in listDestino){
		
		let infoRetiro = {
			comuna_origen_id: remitente?.comuna_origen_id,
			comuna_destino_id: listDestino[index].comuna,
			remitente: {
				rut: remitente?.rut_remitente,
				razon_social: remitente?.razon_social_remitente,
				direccion: remitente?.direccion_retiro,
				telefono: remitente?.telefono_remitente,
				email: typeof remitente?.email_remitente ==="undefined" ? remitente?.email_remitente : "",
				internal_code_number: remitente?.codigo_interno ?? "",
				sucursal_origen_id: remitente?.sucursal_origen_id
			},
			destinatario: {
				rut: listDestino[index].rut,
				razon_social: listDestino[index].razon_social?.toUpperCase(),
				direccion: listDestino[index].direccion,
				telefono: listDestino[index].telefono,
				email: listDestino[index].email,
				internal_code_number: "",
				sucursal_destino_id: listDestino[index].sucursal_destino_id
			},
			cargas: listDestino[index].listPackages,
			observacion: listDestino[index].observacion,
			forma_pago_id: listDestino[index].forma_pago_id,
		}

		dataDestino[index] = formatDataCreated(infoRetiro)
	}

	let cliente = {
		rut: remitente?.rut_remitente,
		razon_social: remitente?.razon_social_remitente,
		direccion: remitente?.direccion_retiro,
		telefono: remitente?.telefono_remitente,
		email: typeof remitente?.email_remitente ==="undefined" ? remitente?.email_remitente : "",
		codigo_interno: remitente?.codigo_interno,
		comuna_id: remitente?.comuna_origen_id,
		sucursal_id: remitente?.sucursal_origen_id,
		turn: ""
	}

	let observacion =  remitente?.observacion

	return { dataDestino, cliente, observacion }
}

export const formatDataCreated = (data) => {

	let comunas = [
		{
			type: "origen",
			office_id: data?.comuna_origen_id,
		},
		{
			type: "destino",
			office_id: data?.comuna_destino_id
		}
	]

	let clientes = [
		{
            "type": "cliente_principal",
            "rut": data?.remitente?.rut,
            "isPrincipal": true,
            "direccion": data?.remitente?.direccion,
            "telefono": data?.remitente?.telefono,
            "razon_social": data?.remitente?.razon_social?.toUpperCase(),
            "codigo_interno": data?.remitente?.internal_code_number,
			"sucursal_id": data?.remitente?.sucursal_origen_id,
			"comuna_id": data?.comuna_origen_id,
			"email": data?.remitente?.email
        },
		{
            "type": "remitente",
            "rut": data?.remitente?.rut,
            "isPrincipal": false,
            "direccion": data?.remitente?.direccion,
            "telefono": data?.remitente?.telefono,
            "razon_social": data?.remitente?.razon_social?.toUpperCase(),
            "codigo_interno": data?.remitente?.internal_code_number,
			"sucursal_id": data?.remitente?.sucursal_origen_id,
			"comuna_id": data?.comuna_origen_id,
			"email": data?.remitente?.email
        },
        {
            "type": "destinatario",
            "rut": data?.destinatario?.rut,
            "isPrincipal": false,
            "direccion": data?.destinatario?.direccion,
            "telefono": data?.destinatario?.telefono,
            "razon_social": data?.destinatario?.razon_social?.toUpperCase(),
            "codigo_interno": data?.destinatario?.internal_code_number,
			"sucursal_id": data?.destinatario?.sucursal_destino_id,
			"comuna_id": data?.comuna_destino_id,
			"email": data?.destinatario?.email
        },
	]

	let cargas = data?.cargas.map((item, index) => {
		return {
			high: typeof item?.alto ==="undefined" ? "" : item?.alto,
			width: typeof item?.ancho ==="undefined" ? "" : item?.ancho,
			long: typeof item?.largo ==="undefined" ? "" : item?.largo,
			quantity_package: typeof item?.bulto ==="undefined" ? "" : item?.bulto,
			quantity_kg: typeof item?.peso ==="undefined" ? 0 : item?.peso,
			quantity_cubic_meters: typeof item?.metraje_cubico ==="undefined" ? 0 : item?.metraje_cubico,
			net_value: typeof item?.valor_neto ==="undefined" ? 0 : item?.valor_neto,
			description: `Bulto ${index+1}`
		}
	})
	let info = {
		observacion: typeof data?.observacion === "undefined" ? "" : data?.observacion,
		comunas,
		clientes,
		cargas,
		forma_pago_id: typeof data?.forma_pago_id === "undefined" ? "" : data?.forma_pago_id
	}

	return info
}

export const formatListViewRetiro = (list, data, retiro) => {

	try{
		let listDskUpdate = list

		listDskUpdate.unshift({
			camion_numero_interno: null,
			cant_preorden: data?.destinations.length,
			check: false,
			classBorder: "",
			classSend: "option-details__dash",
			comuna_id: data?.comuna_origen_id,
			comuna_name: data?.comuna_name,
			detalle_interno: data?.observacion,
			direccion_retiro: data?.direccion_retiro,
			email: data?.email_remitente,
			fecha: retiro?.fecha,
			id: retiro?.id,
			label_web: retiro?.estado,
			numero_retiro: retiro?.numero, 
			razon_social_remitente: data?.razon_social_remitente,
			rut_remitente: data?.rut_remitente,
			sucursal_id: data?.sucursal_origen_id,
			sucursal_name: data?.sucursal_name,
			telefono_remitente: data?.telefono_remitente,
			titleMain: `#${retiro?.numero}`,
			titleSecondary: retiro?.fecha.replaceAll('-', ' / '),
			type_option: "label",
			ultimo_estado: retiro?.estado,
			valueMain: retiro?.estado,
			valueSecondary: data?.direccion_retiro,
		})
		
		return listDskUpdate

	}catch(error){

		return []
	}
}

export const formatListViewOrden = (form, listDsk, retiro) => {

	try{
		
		let listDskUpdate = listDsk
		for(let index in form?.destinations){
			let item = form?.destinations[index]
			listDskUpdate.unshift({
				orden_flete_id: retiro?.ordenes[index]?.id,
				ciudad_destino_id: item?.comuna,
				oficina_destino_id: item?.sucursal_destino_id,
				ciudad_destino_nombre: item?.comuna_name,
				oficina_destino_nombre: item?.sucursal_name,
				razon_social_remitente: form?.razon_social_remitente,
				rut_destinatario: item?.rut,
				razon_social_destinatario: item?.razon_social,
				direccion_destinatario: item?.direccion,
				telefono_avisas: item?.telefono,
				email: item?.email,
				numero_preorden: retiro?.ordenes[index]?.numero,
				tipo_envio_id: null,
				tipo_envio: null,
				forma_pago: null,
				documentacion_id: null,
				numero_documento_carga: null,
				valor_declarado_carga: 0,
				forma_pago_id: null,
				email_orden: null,
				observaciones: null,
				fecha_preorden: retiro?.fecha,
				cargas: item?.listPackages,
				total_bultos: 0,
				total_kilos: 0,
				total_metro_cubico: 0,
				subtotal_orden: null,
				descuento: null,
				valor_neto: null,
				valor_iva: null,
				monto_descuento: 0.00,
				total: 0,
				ultimo_estado: "Pendiente de retiro",
				label_web: "Pendiente",
				titleMain: `#${retiro?.ordenes[index]?.numero}`,
				titleSecondary: retiro.fecha,
				valueSecondary: item?.direccion,
				valueMain: "Pendiente",
				type_option: "label",
				classBorder: "",
				classSend: "option-details__dash"
			})
		}

		return listDskUpdate
	}catch(error){
		return []
	}
	
}

export const formatListViewOrdenPage = (form, listDsk, retiro) => {

	try{
		
		let listDskUpdate = listDsk
		for(let index in form?.destinations){
			let item = form?.destinations[index]
			listDskUpdate.unshift({
				orden_flete_id: retiro?.ordenes[index]?.id,
				ciudad_destino_id: item?.comuna,
				oficina_destino_id: item?.sucursal_destino_id,
				ciudad_destino_nombre: item?.comuna_name,
				oficina_destino_nombre: item?.sucursal_name,
				razon_social_remitente: form?.razon_social_remitente,
				rut_destinatario: item?.rut,
				razon_social_destinatario: item?.razon_social,
				direccion_destinatario: item?.direccion,
				telefono_avisas: item?.telefono,
				email: item?.email,
				numero_preorden: retiro?.ordenes[index]?.numero,
				tipo_envio_id: null,
				tipo_envio: null,
				forma_pago: null,
				documentacion_id: null,
				numero_documento_carga: null,
				valor_declarado_carga: 0,
				forma_pago_id: null,
				email_orden: null,
				observaciones: null,
				fecha_preorden: retiro?.fecha,
				cargas: item?.listPackages,
				total_bultos: 0,
				total_kilos: 0,
				total_metro_cubico: 0,
				subtotal_orden: null,
				descuento: null,
				valor_neto: null,
				valor_iva: null,
				monto_descuento: 0.00,
				total: 0,
				ultimo_estado: "Pendiente de retiro",
				label_web: "Pendiente",
				titleMain: item?.razon_social,
				titleSecondary: retiro.fecha.replaceAll('-', ' / '),
				valueSecondary: `#${retiro?.ordenes[index]?.numero}`,
				valueMain: "Pendiente",
				type_option: "label",
				classBorder: "",
				classSend: "option-details__dash"
			})
		}

		return listDskUpdate
	}catch(error){
		return []
	}
	
}
